@font-face {
  font-family: 'Calibre';
  src: url('CalibreWeb-Regular.eot');
  src: url('CalibreWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('CalibreWeb-Regular.woff2') format('woff2'),
    url('CalibreWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('CalibreWeb-Medium.eot');
  src: url('CalibreWeb-Medium.eot?#iefix') format('embedded-opentype'),
    url('CalibreWeb-Medium.woff2') format('woff2'),
    url('CalibreWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('CalibreWeb-Semibold.eot');
  src: url('CalibreWeb-Semibold.eot?#iefix') format('embedded-opentype'),
    url('CalibreWeb-Semibold.woff2') format('woff2'),
    url('CalibreWeb-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
